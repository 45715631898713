<template>
  <div>
    입찰 디폴트 레이아웃<br>
    <button @click="openModal('auction-history-modal')">경매 내역</button><br>
    <button @click="movePage('/openmarket/bid')">입찰(페이지)</button>
    <auction-history-modal></auction-history-modal>
  </div>
</template>

<script>
import AuctionHistoryModal from "@/components/modal/AuctionHistoryModal";

export default {
  name: "BidDefaultLayout",
  mixins: [],
  components: {
    AuctionHistoryModal,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    movePage(url){
      this.$router.push(url);
    },
    openModal(name){
      this.$modal.show(name);
      $("body").addClass("modal-open");
    }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>